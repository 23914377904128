import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import ContatoForm from "../components/ContatoForm"

export default function contato() {
  return (
    <Layout color="white" breadcrumbs={["Contato"]} item="3">
        <section class="banner_area">
        <div class="banner_inner d-flex align-items-center">
            <div class="overlay"></div>
            <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                <div class="banner_content text-center">
                    <h2>Fale conosco</h2>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
        <div class="section_gap registration_area">
        <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="row clock_sec clockdiv" id="clockdiv">
              <div class="col-lg-12 align-text-top">
                <h1 class="mb-3">Conheça a Vital</h1>
                <p>Faça-nos uma visita no endereço:</p>
                <p>Rua Guaíra 3170, Guarapuava - PR</p>
                <p><b>(42) 3035 3456</b></p>
              </div>
              </div>
          </div>
          <div class="col-lg-8">
            <div class="register_form">
              <h3>Entre em contato conosco pelo formulário abaixo</h3>
                <ContatoForm cta="Entrar em Contato" msg="A" />
              </div>
          </div>
        </div>
      </div>
    </div>
       </Layout>
  );
}