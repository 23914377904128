import React, { useState } from "react"
import emailjs from 'emailjs-com';



const Sucesso = () => {
    return (
        <div style={{textAlign:"center"}}>
            <p style={{fontSize:"100px",color:"darkblue",margin:"50px"}}>✔</p>
            <h3>Recebemos sua mensagem!</h3>
            <p>Entraremos em contato em breve</p>
        </div>
    )
};


const ContatoForm = ({cta, curso, msg}) => {
    const [enviado, setEnviado] = useState(false);

    function enviarEmail(e) {
        e.preventDefault();

        emailjs.sendForm('gmail', 'template_JphbXmf7', e.target, 'user_VGTQsh8og4rU4mCsUbVCi')
            .then((result) => {
                console.log(result.text);
                setEnviado(true)
            }, (error) => {
                console.log(error.text);
            });
    }
  return (
        <>
            {enviado
            ? <Sucesso /> 
            : (
            <form
                className="form_area"
                id="myForm"
                onSubmit={enviarEmail}
            >
                <div className="row">
                <div className="col-lg-12 form_group">
                    <input
                    name="nome"
                    placeholder="Seu Nome"
                    required
                    type="text"
                    />
                    <input
                    name="fone"
                    placeholder="Seu Telefone"
                    required
                    type="tel"
                    />
                    <input
                    name="email"
                    placeholder="Seu E-mail"
                    pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$"
                    required
                    type="email"
                    />
                    <input
                      name="curso"
                      value={curso}
                      readOnly="readonly"
                      type={curso?"text":"hidden"}
                    />
                    <textarea
                        placeholder={msg?"Sua mensagem":"Gostaria de entrar em contato com vocês"}
                        style={{display:(!msg&&"none")}}
                    ></textarea>
                </div>
                <div className="col-lg-12 text-center">
                    <button type="submit" className="primary-btn">{cta}</button>
                </div>
                </div>
            </form>
            )}
        </>
)};


export default ContatoForm;